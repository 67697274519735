@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&family=Nunito+Sans:opsz,wght@6..12,400;6..12,500;6..12,600;6..12,700;6..12,900&family=Nunito:wght@400;500;600;700;900&family=Overpass:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Inconsolata, monospace;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #FAFAFA;
}

/* Below (line 21- 30) CSS to handle the footer on bottom */

.main-container {
  flex: 1;
}

.MuiPopover-paper {
  background-color: #ffffff !important;
  border-radius: 20px !important;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline::before {
  display: none !important;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline::after {
  display: none !important;
}
